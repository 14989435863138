const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");

export default function useRefreshToken() {
  const refreshToken = async () => {
    const { data: refreshData } = await useFetch<AuthRefresh>(`${BASE_URL}/api/v1/auth/refresh-token/`, {
      body: {
        refresh: auth.value.refresh,
      },
      method: "POST",
    });
    if (refreshData.value) {
      auth.value.access = refreshData.value.access;
      auth.value.refresh = refreshData.value.refresh;
    }
  };
  return { refreshToken };
}
